// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qcRCmMGm3KTu6bvtujD1{display:block}.rsGAM6Uwj4lIn_ePUkGC:after{width:100%;display:block;content:\"\";border-top:1px solid rgba(67,75,79,.1490196078);box-shadow:0px 1px 0px #fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "qcRCmMGm3KTu6bvtujD1",
	"seeMoreUnderline": "rsGAM6Uwj4lIn_ePUkGC"
};
export default ___CSS_LOADER_EXPORT___;
